<template>
  <action-dispatcher action="bibliography/get" class="view-bibliography">
    <div class="mb-3">
      <h3>Livros, slides e materiais das aulas</h3>
      <small class="text-secondary">
        Aqui você encontrará todas os recursos disponibilizados
      </small>
    </div>
    <div class="row">
      <div
        v-for="(bib, index) in bibliography"
        :key="`exam-${index}`"
        class="col-lg-4"
      >
        <card class="text-center m-2">
          <img :src="bib.thumbnailUrl" class="img-fluid thumbnail mb-4" />
          <h5>
            {{ bib.title }}
          </h5>
          <div class="mb-4 text-secondary">{{ bib.module }}</div>
          <button
            type="button"
            class="btn btn-outline-primary btn-block mb-2"
            @click="copyToClipBoard(bib.url)"
          >
            Copiar URL
          </button>
          <a :href="bib.url" target="_blank" class="btn btn-success btn-block">
            Acessar
          </a>
        </card>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import copy from "copy-text-to-clipboard";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState("bibliography", ["bibliography"]),
    completed() {
      if (
        !this.$user.user ||
        !this.$user.user.progress ||
        !this.$user.user.progress.exams
      ) {
        return [];
      }

      return this.$user.user.progress.exams;
    }
  },
  methods: {
    markAsCompleted(exam) {
      let isAnRemove = false;
      let progress = {
        exams: []
      };

      if (this.$user.user && this.$user.user.progress) {
        progress = {
          ...progress,
          ...this.$user.user.progress
        };
      }

      const index = progress.exams.indexOf(exam.id);

      if (index === -1) {
        progress.exams.push(exam.id);
      } else {
        progress.exams.splice(index, 1);
        isAnRemove = true;
      }

      this.$store
        .dispatch("user/updateProgress", {
          email: this.$user.user.email || this.$user.user.id,
          progress
        })
        .then()
        .catch(() => {});
    },
    copyToClipBoard(text) {
      copy(text);
      this.$message.success("URL copiada!");
    }
  }
};
</script>

<style lang="scss" scoped>
.view-bibliography {
  .thumbnail {
    max-height: 130px;
  }
}
</style>
